<template>
	<div>
		<div
			v-if="account.account_status === 'pending' && account.account_type === 'adv'"
			class="notification notification--error text-body-3"
		>
			<p class="notification__message">
				Your account is on hold for additional moderation. All traffic has been temporarily stopped. For more details,
				please contact our support team via live chat or email at
				<a href="mailto:support@rollerads.com">support@rollerads.com</a>
			</p>
		</div>

		<div v-for="(message, messageKey) in messages" :key="messageKey">
			<div v-if="!isNotificationDismissed(messageKey) && message.roles.includes(role)" class="notification text-body-3">
				<!-- eslint-disable-next-line vue/no-v-html -->
				<p class="notification__message" v-html="message.text" />
				<button class="notification__button" @click="onDismissClick(messageKey)">Dismiss</button>
			</div>
		</div>

		<div v-if="allowBeta && !isNotificationDismissed('beta')" class="notification text-body-3">
			<p v-if="betaFeatures.length" class="notification__message">
				Dear user, the following beta-features are available to you now: {{ betaFeatures.join(', ') }}. We would like to
				hear your opinion, please share your feedback at
				<a href="mailto:support@rollerads.com">support@rollerads.com</a>
			</p>
			<p v-else class="notification__message">
				Dear user, some beta-features are available to you now. We would like to hear your opinion, please share your
				feedback at
				<a href="mailto:support@rollerads.com">support@rollerads.com</a>
			</p>
			<button class="notification__button" @click="onDismissClick('beta')">Dismiss</button>
		</div>

		<div v-if="account.account_type === 'adv' && !isNotificationDismissed('xmasPromo')" class="notification text-body-3">
			<p class="notification__message">
				🎅 Let the Xmas fun begin! Open our
				<b>Advent Calendar</b>
				from December 1–24 for daily surprises.
				<a href="https://bit.ly/4i9sIdV">Unwrap today's gift!</a>
			</p>
			<button class="notification__button" @click="onDismissClick('xmasPromo')">Dismiss</button>
		</div>

		<div v-if="isDirectClickUsers && !isNotificationDismissed('isDirectClick')" class="notification text-body-3">
			<p class="notification__message">
				<b>New Format Launch</b>
				🚀 Direct Click is now a standalone ad format! All your campaigns with the
				<b>Direct Click preset</b>
				have been automatically upgraded. Got questions? Contact your account manager or the support team—we're here to
				help.
			</p>
			<button class="notification__button" @click="onDismissClick('isDirectClick')">Dismiss</button>
		</div>
	</div>
</template>

<script>
	import User from '@/services/local/UserService';

	export default {
		data() {
			const notifications = new Set();
			if (typeof localStorage.notifications === 'string') {
				localStorage.notifications.split(',').forEach((k) => notifications.add(k));
			}

			if (typeof localStorage.messageKey === 'string') {
				notifications.add(localStorage.messageKey);
				delete localStorage.messageKey;

				localStorage.notifications = Array.from(this.$data.notifications).join(',');
			}

			return {
				notifications,
				betaFeatures: [],
				allowBeta: User.isFeatureEnabled('beta'),
				role: User.currentUser.user.role_type,
				account: User.currentUser.account || {},
				messages: {},
				messages_template: {
					downtime030224: {
						text: "⚙ 🚧 Atention needed. Dear user, on 03/02 from 4-6 AM UTC, we'll conduct service maintenance. During this time, access to your account may be unavailable. Campaigns and monetization will remain active.",
						roles: ['adv', 'pub'],
					},
				},
			};
		},

		computed: {
			isDirectClickUsers() {
				return [48954, 38200, 51575, 46431, 3050, 2092, 1127, 63607, 29777, 1123, 1037, 10195].includes(
					User.currentUser.account.account_id
				);
			},
		},

		methods: {
			isNotificationDismissed(messageKey) {
				return this.$data.notifications.has(messageKey);
			},

			onDismissClick(messageKey) {
				this.$data.notifications.add(messageKey);
				localStorage.notifications = Array.from(this.$data.notifications).join(',');
			},
		},
	};
</script>
